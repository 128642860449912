import AvTimerIcon from "@material-ui/icons/AvTimer";
import SettingsIcon from "@material-ui/icons/Settings";
import ListAltIcon from "@material-ui/icons/ListAlt";
// import { MdCardGiftcard } from "react-icons/md";
// import { BiBuildingHouse, BiUser } from "react-icons/bi";
// import { IoCartOutline } from "react-icons/io5";
// import { RiCalendarEventLine } from "react-icons/ri";
// import { FaFileInvoice } from "react-icons/fa";
import { AiOutlineUser } from "react-icons/ai";
import { IoFileTrayStackedOutline } from "react-icons/io5";

const baseName = "/dashboard";
const menu = [
  {
    name: "app-sidebar-dashboard",
    path: baseName,
    icon: AvTimerIcon,
  },
  // {
  //   name: "app-sidebar-client",
  //   icon: AiOutlineUser,
  //   submenu: [
  //     {
  //       name: "app-sidebar-client-list",
  //       path: `${baseName}/client`,
  //     },
  //     {
  //       name: "app-sidebar-client-new",
  //       path: `${baseName}/client/create`,
  //     },
  //   ],
  // },
  // {
  //   name: "app-sidebar-candidate",
  //   icon: IoFileTrayStackedOutline,
  //   submenu: [
  //     {
  //       name: "app-sidebar-candidate-list",
  //       path: `${baseName}/candidate`,
  //     },
  //     {
  //       name: "app-sidebar-candidate-new",
  //       path: `${baseName}/candidate/create`,
  //     },
  //   ],
  // },
  // {
  //   name: "app-sidebar-job",
  //   icon: ListAltIcon,
  //   submenu: [
  //     {
  //       name: "app-sidebar-job-list",
  //       path: `${baseName}/job`,
  //     },
  //     {
  //       name: "app-sidebar-job-new",
  //       path: `${baseName}/job/create`,
  //     },
  //   ],
  // },
  {
    name: "app-sidebar-setting",
    icon: SettingsIcon,
    submenu: [
      {
        name: "app-sidebar-setting-profile",
        path: `${baseName}/setting/profile`,
      },
    ],
  },
];

export default menu;
