import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(96% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            marginRight: '2%',
        },
        top: 25,
        borderRadius: 15,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#cfdfff',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    image: {
        width: theme.spacing(20),
        height: theme.spacing(15),
        marginBottom: theme.spacing(1.5),
    },
    marginY: {
        margin: theme.spacing(1, 0),
    },
    btnLabel: {
        justifyContent: 'start',
    },
    endIcon: {
        flexGrow: 1,
        justifyContent: 'flex-end',
        fontSize: '1rem'
    },
    paper: {
        padding: theme.spacing(2.5, 3),
        marginTop: theme.spacing(4),
        margin: theme.spacing(0, 4),
        borderRadius: '1rem',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    appBarButton: {
        '&>*': {
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1)
            },
            marginLeft: theme.spacing(0)
        }
    },
    toolbar: {
        paddingRight: theme.spacing(1)
    },
    divider: {
        height: '40px',
        alignSelf: 'center',
        marginLeft: theme.spacing(2)

    },
}));

export default useStyles;