import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, ListItemIcon, Collapse } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import menuList from "./menu";
import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#a2a2a2",
    marginTop: "140px",
    flexGrow: 1,
    minHeight: "0px",
    overflowY: "overlay",
    "&  a": {
      paddingLeft: "24px",
    },
    "& svg": {
      color: "#a2a2a2",
      fontSize: "22px",
    },
    "& .MuiCollapse-container ": {
      "& a": {
        padding: "5px 0 5px 25px",
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: "40px",
    },
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main}!important`,
    "& svg": {
      color: "white",
    },
    color: "white",
  },
  subSelected: {
    backgroundColor: `${theme.palette.primary.light}!important`,
    "& svg": {
      color: "white",
    },
    color: "white",
  },
}));

const SubMenu = ({ handleClose }) => {
  const classes = useStyles();
  const history = useHistory();
  // const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    for (const [index, item] of menuList.entries()) {
      if (!item.submenu) {
        if (item.path.includes(history.location.pathname)) {
          setSelectedIndex(index + 1);
          break;
        }
      } else if (item.submenu.length > 0) {
        for (const [subIndex, submenu] of item.submenu.entries()) {
          if (
            submenu.path === history.location.pathname ||
            // match base pathname
            history.location.pathname.includes(
              `/${submenu.path.split("/").slice(0, 2).join("/")}`
            )
          ) {
            setSelectedIndex(index + 1 + (subIndex + 1) / 100);
            break;
          }
        }
      }
    }
  }, [history.location.pathname]);

  return (
    <div className={classes.root}>
      <List>
        {menuList.map((item, index) => (
          <React.Fragment key={index}>
            {!item.submenu || item.submenu.length > 0 ? (
              <ListItem
                disableRipple
                button
                key={index}
                className={classes.link}
                classes={{ selected: classes.selected }}
                onClick={() =>
                  !item.submenu
                    ? setSelectedIndex(index + 1)
                    : setSelectedIndex(index + 1.01)
                }
                selected={
                  !item.submenu
                    ? selectedIndex === index + 1
                    : selectedIndex >= index + 1 && selectedIndex < index + 2
                }
                component={Link}
                to={!item.submenu ? item.path : item.submenu[0].path}
              >
                {" "}
                <ListItemIcon>
                  {item.icon && React.createElement(item.icon)}
                </ListItemIcon>
                <ListItemText>{t(item.name).toUpperCase()}</ListItemText>
                {item.submenu && (
                  <ListItemIcon>
                    <ExpandMoreIcon />
                  </ListItemIcon>
                )}
              </ListItem>
            ) : null}
            {item.submenu ? (
              <Collapse
                in={selectedIndex >= index + 1 && selectedIndex < index + 2}
              >
                {item.submenu.map((submenu, subIndex) => (
                  <ListItem
                    key={subIndex}
                    disableRipple
                    button
                    className={classes.link}
                    classes={{ selected: classes.subSelected }}
                    onClick={() => {
                      setSelectedIndex(index + 1 + (subIndex + 1) / 100);
                      handleClose();
                    }}
                    selected={
                      selectedIndex === index + 1 + (subIndex + 1) / 100
                    }
                    component={Link}
                    to={submenu.path}
                  >
                    <ListItemIcon></ListItemIcon>
                    <ListItemText>{t(submenu.name).toUpperCase()}</ListItemText>
                  </ListItem>
                ))}
              </Collapse>
            ) : null}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default SubMenu;
