import { createSelector } from "reselect";

const authReducerSelector = state => state.authReducer;

export const loginSelector = createSelector(
  authReducerSelector,
  ({
    loginResponse,
    isLoadingLogin,
    isErrorLogin,
  }) => ({
    loginResponse,
    isLoadingLogin,
    isErrorLogin,
  })
);

export const resetPasswordSelector = createSelector(
  authReducerSelector,
  ({
    resetPasswordResponse,
    isErrorResetPassword,
    isLoadingResetPassword,
  }) => ({
    resetPasswordResponse,
    isErrorResetPassword,
    isLoadingResetPassword,
  })
);