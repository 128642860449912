import React, { useState, useEffect } from "react";
import {
  Container,
  Box,
  Paper,
  TextField,
  InputAdornment,
  Button,
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { loginRequest } from "src/redux/auth/action";
import { loginSelector } from "src/redux/auth/selector";

import { ROUTE_PATH } from "src/utils/data-util";
import useStyles from "./style";
import clsx from "clsx";

export default function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [loginAlert, setLoginAlert] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { usernameRef, ...usernameRest } = register("username", {
    required: true,
  });
  const { passwordRef, ...passwordRest } = register("password", {
    required: true,
  });

  const { loginResponse } = useSelector(loginSelector);

  const onLoginSubmit = (data) => {
    dispatch(loginRequest(data));
  };

  const handleLoginAlert = (open) => {
    setLoginAlert(open);
  };

  useEffect(() => {
    console.log("login", loginResponse?.login);
    if (loginResponse?.login) {
      // console.log('login');
      // history.push(ROUTE_PATH.dashboard);
    } else if (loginResponse?.message) {
      handleLoginAlert(true);
    }
  }, [loginResponse, history]);

  return (
    <React.Fragment>
      <Box className={classes.positionFix}>
        <Paper
          className={clsx(classes.backGroundStyle, classes.radiusLeft)}
          elevation={0}
          square
        />
        <Paper
          className={clsx(classes.backGroundStyle, classes.radiusRight)}
          elevation={0}
          square
        />
      </Box>
      <Container className={classes.container}>
        <Paper className={classes.paper} elevation={3}>
          <Avatar
            className={classes.image}
            classes={{ img: classes.logo }}
            variant="square"
            src="./image/Fyrefly_Full_Black.png"
          />
          <form
            className={classes.inputContainer}
            onSubmit={handleSubmit(onLoginSubmit)}
          >
            <Box width="100%" height="60px">
              <TextField
                className={classes.textField}
                fullWidth
                placeholder="Username or E-mail"
                name="username"
                {...usernameRest}
                inputRef={usernameRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.username && (
                <Box fontSize="0.8rem" color="error.main">
                  Username or E-mail is required
                </Box>
              )}
            </Box>
            <Box width="100%" height="60px">
              <TextField
                className={classes.textField}
                fullWidth
                placeholder="Password"
                name="password"
                type="password"
                {...passwordRest}
                inputRef={passwordRef}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <VpnKeyOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && (
                <Box fontSize="0.8rem" color="error.main">
                  Password is required
                </Box>
              )}
            </Box>
            <Button
              className={classes.btn}
              variant="contained"
              color="primary"
              type="submit"
            >
              Login
            </Button>
            <Button
              variant="outlined"
              onClick={() => history.push("/reset-password")}
              style={{
                background: "none",
                border: "none",
                color: "grey",
                fontSize: 10,
                textDecoration: "underline",
              }}
            >
              Reset Password
            </Button>
          </form>
        </Paper>
      </Container>

      {!loginResponse?.login && (
        <Dialog
          open={loginAlert}
          fullWidth
          maxWidth="xs"
          onClose={() => handleLoginAlert(false)}
        >
          <DialogTitle>Login Failed</DialogTitle>
          <DialogContent>
            <DialogContentText>{loginResponse?.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleLoginAlert(false)}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
