import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_EN } from "./en";
// import { TRANSLATIONS_KH } from "./kh/translation";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    //   default language
    fallbackLng: "en",
    load: "languageOnly",
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      // kh: {
      //   translation: TRANSLATIONS_KH,
      // },
    },
  });
