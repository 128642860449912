import { AUTH_ACTION } from "./action";

const initialState = {
  loginResponse: { login: true },
  isErrorLogin: false,
  isLoadingLogin: false,

  resetPasswordResponse: {},
  isErrorResetPassword: false,
  isLoadingResetPassword: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ACTION.LOGIN_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_ACTION.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_ACTION.LOGIN_FAILURE:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_ACTION.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_ACTION.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_ACTION.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        ...action.payload,
      };

    case AUTH_ACTION.LOGOUT_REQUEST:
      return {
        ...state,
        loginResponse: {
          login: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
