import axios from "axios";

const Auth = {
  login: async (data) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/auth`,
      data,
      { withCredentials: true }
    );
    // console.log("login:", response);
    return response;
  },
  logout: async (data) => {
    const response = axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/auth/logout`
    );

    return response;
  },
  resetPW: async (data) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/auth/reset-password`,
      data,
      { withCredentials: true }
    );
    // console.log("login:", response);
    return response;
  },
};

export default Auth;
