import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    positionFix: {
        height: '40%',
        width: '100%',
        position: 'fixed',
        display: 'flex',
        zIndex: -1
    },
    backGroundStyle: {
        height: '100%',
        width: '50%',
        backgroundColor: '#cfdfff',
    },
    radiusLeft: {
        borderBottomLeftRadius: '95%',
    },
    radiusRight: {
        borderBottomRightRadius: '95%',
    },
    container: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    paper: {
        padding: theme.spacing(2),
        borderRadius: '8px',
        opacity: 0.8,
        minHeight: '350px',
        minWidth: '370px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    inputContainer: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    emailField: {
        '&>*>input': {
            textAlign: 'center',
            width: '100%'
        }
    },
    textField: {
        '&>*>input': {
            textAlign: 'center',
            width: '80%'
        }
    },
    btn: {
        width: '50%'
    },
    btnReset: {
        marginBottom: theme.spacing(2)
    },
    image: {
        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    logo: {
        objectFit: 'contain'
    }
}));

export default useStyles;