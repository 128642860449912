import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { loginSelector } from "src/redux/auth/selector";

const PrivateRoute = ({ component: Component, children, ...rest }) => {
  const { loginResponse } = useSelector(loginSelector);

  return (
    <Route {...rest}>
      {loginResponse?.login ? children : <Redirect to="/login" />}
    </Route>
  );
};

export default PrivateRoute;
