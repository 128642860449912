import { combineReducers } from 'redux'
import authReducer from './auth/reducer';
import dashboardReducer from './dashboard/reducer';

const allReducer = combineReducers({
  authReducer,
  dashboardReducer
});

export default allReducer;
