import { Avatar, Grid, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((themes) => ({
  avatar: {
    width: "60px",
    height: "60px",
    border: `3px solid ${themes.palette.primary.main}`,
  },
}));

const MiniProfile = ({ username, organization, profileUrl }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Avatar src={profileUrl} className={classes.avatar} />
      </Grid>
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h3">{username}</Typography>
          </Grid>
          <Grid item>
            <Typography>{organization}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MiniProfile;
