import { call, put, takeLatest } from 'redux-saga/effects';
import {
  DASHBOARD_ACTION,
  getDashboardDataSuccess,
  getDashboardDataFailure
} from './action';

import DASHBOARD from 'src/api/dashboard';

export default function* dashboardSaga() {
  yield takeLatest(DASHBOARD_ACTION.GET_DASHBOARD_DATA_REQUEST, getDashboardDataRequestWatcher);
}

function* getDashboardDataRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(DASHBOARD.getDashboardData, data);
    const dashboardData = response.data;

    yield put(getDashboardDataSuccess({ dashboardData }));

  } catch (error) {
    const dashboardData = error.response.data;
    yield put(getDashboardDataFailure({ dashboardData }));
  }
}
