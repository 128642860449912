import React from "react";
import {
  AppBar,
  IconButton,
  makeStyles,
  Toolbar,
  Box,
} from "@material-ui/core";
import { IoMenu } from "react-icons/io5";
// import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
// import Profile from "./profile";
// import LanguageSwitcher from "src/components/langugage-switcher";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#00AAE9",
    color: "black",
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Navbar = ({ handleOpen }) => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.root} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <IconButton onClick={handleOpen}>
          <IoMenu />
        </IconButton>
        <Box display="flex" alignItems="center">
          {/* <IconButton>
            <NotificationsNoneIcon />
          </IconButton> */}
          {/* <Profile /> */}
          {/* <LanguageSwitcher /> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
