import { Paper, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    boxShadow: "0 0 4px #bbbbbb !important",
    padding: "2.5em 2.5em",
  },
}));

const PaperContainer = ({ children }) => {
  const classes = useStyles();

  return <Paper className={classes.paperContainer}>{children}</Paper>;
};

export default PaperContainer;
