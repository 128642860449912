export const AUTH_ACTION = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
};

export const loginRequest = (data) => ({
  type: AUTH_ACTION.LOGIN_REQUEST,
  payload: data,
});

export const loginSuccess = ({ loginResponse }) => ({
  type: AUTH_ACTION.LOGIN_SUCCESS,
  payload: { loginResponse },
});

export const loginFailure = ({ loginResponse }) => ({
  type: AUTH_ACTION.LOGIN_FAILURE,
  payload: { loginResponse },
});

export const resetPasswordRequest = (data) => ({
  type: AUTH_ACTION.RESET_PASSWORD_REQUEST,
  payload: data,
});

export const resetPasswordSuccess = ({ resetPasswordResponse }) => ({
  type: AUTH_ACTION.RESET_PASSWORD_SUCCESS,
  payload: { resetPasswordResponse },
});

export const resetPasswordFailure = ({ resetPasswordResponse }) => ({
  type: AUTH_ACTION.RESET_PASSWORD_FAILURE,
  payload: { resetPasswordResponse },
});

export const logoutRequest = () => ({
  type: AUTH_ACTION.LOGOUT_REQUEST,
});
