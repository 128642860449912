import axios from 'axios';

const Dashboard = {
    getDashboardData: async () => {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/dashboard`,
            { withCredentials: true }
        );
        // console.log("getInfo:", response);
        return response;
    },
};

export default Dashboard;
