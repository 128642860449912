import React from "react";

import { Provider } from "react-redux";
import { store } from "./redux/store";

import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import { ModalProvider } from "react-modal-hook";
import { TransitionGroup } from "react-transition-group";
import Routes from "./routes";
import theme from "./themes";

import "./App.css";

// console.log(store.getState());

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ModalProvider rootComponent={TransitionGroup}>
            <Routes />
          </ModalProvider>
        </Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
