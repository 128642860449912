import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from "@material-ui/core/";

import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { resetPasswordRequest } from "src/redux/auth/action";
import { resetPasswordSelector } from "src/redux/auth/selector";

import useStyles from "./style";
import clsx from "clsx";
import CustomButton from "src/components/button/custom-button";

const ResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const didMountRef = useRef(false);

  const [resetAlert, setResetAlert] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { emailRef, ...emailRest } = register("email", { required: true });

  const { resetPasswordResponse } = useSelector(resetPasswordSelector);

  const onResetSubmit = (data) => {
    console.log("onResetSubmit", data);
    dispatch(resetPasswordRequest(data));
  };

  const handleResetAlert = (open) => {
    setResetAlert(open);
  };

  useEffect(() => {
    if (didMountRef.current) {
      if (resetPasswordResponse && resetPasswordResponse.message) {
        handleResetAlert(true);
      }
    } else {
      didMountRef.current = true;
    }
  }, [resetPasswordResponse]);

  return (
    <React.Fragment>
      <Box className={classes.positionFix}>
        <Paper
          className={clsx(classes.backGroundStyle, classes.radiusLeft)}
          elevation={0}
          square
        />
        <Paper
          className={clsx(classes.backGroundStyle, classes.radiusRight)}
          elevation={0}
          square
        />
      </Box>
      <Container className={classes.container}>
        <Paper className={classes.paper} elevation={3}>
          <Avatar
            className={classes.image}
            classes={{ img: classes.logo }}
            variant="square"
            src="./image/Fyrefly_Full_Black.png"
          />
          <React.Fragment>
            <form
              className={classes.inputContainer}
              onSubmit={handleSubmit(onResetSubmit)}
            >
              <Box width="100%" height="60px">
                <TextField
                  className={classes.emailField}
                  fullWidth
                  placeholder="Enter your email address"
                  type="email"
                  {...emailRest}
                  inputRef={emailRef}
                />
                {errors.email && (
                  <Box fontSize="0.8rem" color="error.main">
                    Email is required
                  </Box>
                )}
              </Box>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <CustomButton caption="Reset Password" type="submit" />
                </Grid>
                <Grid item>
                  <CustomButton
                    caption="Return to Login"
                    customVariant="danger"
                    onClick={() => history.push("/login")}
                  />
                </Grid>
              </Grid>
            </form>
          </React.Fragment>
        </Paper>
      </Container>
      <Dialog
        open={resetAlert}
        fullWidth
        maxWidth="xs"
        onClose={() => handleResetAlert(false)}
      >
        <DialogTitle>Reset password</DialogTitle>
        <DialogContent>
          <DialogContentText>{resetPasswordResponse.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleResetAlert(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ResetPassword;
