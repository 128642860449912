import { DASHBOARD_ACTION } from "./action";

const initialState = {
  dashboardData: {},
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {

    case DASHBOARD_ACTION.GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        ...action.payload
      };
    case DASHBOARD_ACTION.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case DASHBOARD_ACTION.GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default DashboardReducer;
