import React, { useEffect } from "react";
import { Toolbar, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getDashboardDataRequest } from "src/redux/dashboard/action";

import useStyles from "./style";

export default function Dashboard(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getDashboardDataRequest({}));
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Toolbar />
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography variant="h4">
              Welcome to Fyrefly Candidate dashboard!
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="div">
              The company will contact you through this platform.
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper} >
                            <Box fontSize={30} mb={2}>
                                Clients
                            </Box>
                            <Box fontSize={50} textAlign='right'>
                                {dashboardData.data &&
                                    numeral(dashboardData.data[0].client).format('0,0')
                                }
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.paper} >
                            <Box fontSize={30} mb={2}>
                                Candidates
                            </Box>
                            <Box fontSize={50} textAlign='right'>
                                {dashboardData.data &&
                                    numeral(dashboardData.data[0].candidate).format('0,0')
                                }
                            </Box>
                        </Paper>
                    </Grid> */}
        </Grid>
      </main>
    </div>
  );
}
