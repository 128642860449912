import axios from "axios";

const MessageApi = {
  create: async (data) =>
    axios.post(`${process.env.REACT_APP_API_URL}/api/v1/message`, data, {
      withCredentials: true,
    }),
  getAll: async (data) =>
    axios.get(`${process.env.REACT_APP_API_URL}/api/v1/message`, {
      withCredentials: true,
    }),
  updateResponse: async (data) =>
    axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/message/response`,
      {
        data,
      },
      { withCredentials: true }
    ),
};

export default MessageApi;
