import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import allReducer from "./all-reducer";
import allSaga from "./all-saga";

const persistConfig = {
  key: "authType",
  storage: storage,
  whitelist: ["authReducer"], // which reducer want to store
};

const pReducer = persistReducer(persistConfig, allReducer);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(pReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(allSaga);

const persistor = persistStore(store);

export { persistor, store };
