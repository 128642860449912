import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import dashboardSaga from './dashboard/saga';

export default function* allSaga() {
  yield all([
    authSaga(),
    dashboardSaga()
  ])
}
