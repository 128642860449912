export const DASHBOARD_ACTION = {
  GET_DASHBOARD_DATA_REQUEST: "GET_DASHBOARD_DATA_REQUEST",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  GET_DASHBOARD_DATA_FAILURE: "GET_DASHBOARD_DATA_FAILURE",
};

export const getDashboardDataRequest = data => ({
  type: DASHBOARD_ACTION.GET_DASHBOARD_DATA_REQUEST,
  payload: data
});
export const getDashboardDataSuccess = ({ dashboardData }) => ({
  type: DASHBOARD_ACTION.GET_DASHBOARD_DATA_SUCCESS,
  payload: { dashboardData }
});
export const getDashboardDataFailure = ({ dashboardData }) => ({
  type: DASHBOARD_ACTION.GET_DASHBOARD_DATA_FAILURE,
  payload: { dashboardData }
});
