// import Client from "src/pages/client";
// import CreateClient from "src/pages/client/create-client";
// import Candidate from "src/pages/candidate";
// import CreateCandidate from "src/pages/candidate/create-candidate";
// import CandidateProfile from "src/pages/candidate/profile";
import DashboardPage from "src/pages/dashboard";
import LogoutPage from "src/pages/logout";
import MessagePage from "src/pages/message";

export const PROTECTED_ROUTES = [
  {
    exact: true,
    path: "/dashboard",
    component: DashboardPage,
  },
  {
    exact: true,
    path: "/message",
    component: MessagePage,
  },
  {
    exact: true,
    path: "/logout",
    component: LogoutPage,
  },
  // {
  //   exact: true,
  //   path: "/dashboard/client/create",
  //   component: CreateClient,
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/candidate/create",
  //   component: CreateCandidate,
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/candidate",
  //   component: Candidate,
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/candidate/:candidateId",
  //   component: CandidateProfile,
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/candidate/:candidateId/edit",
  //   component: CreateCandidate,
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/client",
  //   component: Client,
  // },
];

export const ROUTE_PATH = {
  root: "/",
  login: "/login",
  resetPW: "/reset-password",
  dashboard: "/dashboard",
};
