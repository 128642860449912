import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSelector } from "src/redux/auth/selector";
import { logoutRequest } from "src/redux/auth/action";

export default function LogoutPage() {
  const dispatch = useDispatch();

  const { loginResponse } = useSelector(loginSelector);

  useEffect(() => {
    loginResponse.user && dispatch(logoutRequest());
  }, [loginResponse]);

  return <React.Fragment>Logging Out...</React.Fragment>;
}
