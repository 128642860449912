import { call, put, takeLatest } from "redux-saga/effects";
import {
  AUTH_ACTION,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  resetPasswordSuccess,
  resetPasswordFailure,
} from "./action";
import AUTH from "src/api/auth";

export default function* loginSaga() {
  yield takeLatest(AUTH_ACTION.LOGIN_REQUEST, loginRequestWatcher);
  yield takeLatest(AUTH_ACTION.RESET_PASSWORD_REQUEST, resetPasswordWatcher);
  yield takeLatest(AUTH_ACTION.LOGOUT_REQUEST, logoutRequestWatcher);
}

function* loginRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(AUTH.login, data);
    const loginResponse = response.data;

    yield put(loginSuccess({ loginResponse }));
  } catch (error) {
    const loginResponse = error.response.data;
    yield put(loginFailure({ loginResponse }));
  }
}

function* logoutRequestWatcher(action) {
  try {
    yield call(AUTH.logout);
    // yield put(logoutSuccess());
  } catch (error) {
    const logoutResponse = error.response.data;
    yield put(loginFailure({ logoutResponse }));
  }
}

function* resetPasswordWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(AUTH.resetPW, data);
    const resetPasswordResponse = response.data;

    yield put(resetPasswordSuccess({ resetPasswordResponse }));
  } catch (error) {
    const resetPasswordResponse = error.response.data;
    yield put(resetPasswordFailure({ resetPasswordResponse }));
  }
}
